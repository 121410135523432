<template>
    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard-analytics' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{  $t('label_change_password') }}</b-breadcrumb-item>
            </div>
        </div>
        <b-col cols="12" md="12">
            <b-card
                    no-body
                    class="mb-0"
            >
                <b-card-header class="pb-50">
                    <h5>{{ $t('label_change_password') }}</h5>
                </b-card-header>

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >

                           <validation-provider
                                   #default="validationProps"
                                   :name="$t('label_old_password')"
                                   rules="required"
                           >
                               <b-form-group
                                       :label="$t('label_old_password')"
                                       class="required"
                               >
                                   <b-form-input
                                            type="password"
                                           v-model="itemData.oldPassword"
                                          :placeholder="$t('label_old_password')"
                                           :state="getValidationState(validationProps)"
                                           trim
                                   />

                                   <b-form-invalid-feedback>
                                       {{ validationProps.errors[0] }}
                                   </b-form-invalid-feedback>
                               </b-form-group>
                           </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_new_password')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_new_password')"
                                class="required"
                        >
                            <b-form-input
                                    type="password"
                                    v-model="itemData.userPassword"
                                    :placeholder="$t('label_new_password')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_confirm_password')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_confirm_password')"
                                class="required"
                        >
                            <b-form-input
                                    type="password"
                                    v-model="itemData.confirmPassword"
                                    :placeholder="$t('label_confirm_password')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <div class="d-flex mt-3 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ action == 'editing'? $t('label_edit') : $t('label_save') }}
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                v-on:click="$router.go(-1)"
                        >
                            <!--@click="hide"-->
                            {{ $t('label_cancel') }}

                        </b-button>
                    </div>

                </validation-observer>

            </b-card>
        </b-col>
    </div>
</template>

<script>


    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {
        BCardHeader, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox
    } from 'bootstrap-vue'
    import  fileInput from '@/views/components/fileUploadInput'

    import vSelect from 'vue-select'

    // import {serialize} from 'object-to-formdata';

    export default {
        name: "editItem.vue",
        components: {
            BCardHeader,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            vSelect,
            BFormCheckbox,
            BFormTextarea,

            ValidationProvider,
            ValidationObserver,
            fileInput

        },
        data() {
            return {

                PREFIX: 'change_password',

                itemData: {},

                action: 'adding',

                blankItemData: {
                    oldPassword: '',
                    userPassword: '',
                    confirmPassword: '',
                },

            }
        },
        created() {
            this.initData();
        },
        methods: {
            initData() {
                // let user = this.authUser();
                // this.PREFIX = this.authUser().role;
                let item = Object.assign({}, this.blankItemData);
                // item.id = user.id;
                // for (let prop in item) {
                //     if (user.hasOwnProperty(prop)) {
                //         item[prop] = user[prop];
                //     }
                // }
                this.itemData = item;

                // this.async('get', '/general_settings', {params:{aliases:['marketings_url', 'owner_ip']}}, function (resp) {
                //     this.itemData.marketings_url = marketings_url;
                //     this.itemData.owner_ip = owner_ip;
                // });

            },
            onSubmit() {

                // if (this.action == 'adding') {
                //     this.async('post', '/' + this.PREFIX, this.itemData, function (resp) {
                //         // this.$emit('item-added',{});
                //         // this.$bvModal.hide(this.PREFIX + '-modal');
                //         // this.$router.push({name: 'secretaries_list'});
                //
                //     });
                // }
                // else {
                    this.async('post', '/user/' + this.PREFIX , this.itemData, function (resp) {
                        this.$refs[this.PREFIX + '_FORM'].reset();
                        // this.$emit('item-edited',{});
                        // this.$bvModal.hide(this.PREFIX + '-modal');
                        // this.$router.push({name: 'secretaries_list'});
                        this.initData();
                    });
                // }
            },

        }
    }
</script>

<style scoped>
    .added-img-wrap{
        /*margin-bottom: 15px;*/
        border: 1px solid #BDBDBD;
        border-radius: 15px;
        align-self: center;
        display: flex;
        align-items: center;
        padding: 10px;
        position: relative;
    }
    .added-img-wrap img{
        object-fit: contain;
        width: 100%;
        max-width: 130px;
        height: 100%;
        max-height: 224px;
        border-radius: 5px;
    }
    .delete-img{
        position: absolute;
        border-radius: 50%;
        border-style: none;
        top: 7px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 3px;
        font-size: 15px;
        right: 7px;
        width: 20px;
        height: 20px;
        background-color: #EB5757;
        color: white;
    }
</style>